<template>
  <div>
    <b-row>
      <b-col md="8" sm="12">
        <b-card no-body>
          <b-card-header class="mb-2">
            <div>
              <b-card-title> {{ lang("t_goalManagement") }}</b-card-title>
              <!-- <b-card-sub-title class="mt-1">
                Detaylı grafik görünümü
              </b-card-sub-title> -->
            </div>

            <div class="ml-auto">
              <div class="d-flex align-items-center mb-25">
                <span class="d-flex font-medium-3 font-weight-bolder mr-1">
                  {{ collection_statistics.collection_goal }}
                </span>
                <b-badge class="ml-auto" variant="light-success">{{
                  "%" + collection_statistics.collection_rate
                }}</b-badge>
              </div>
              <div>
                <b-progress
                  style="height: 0.5rem"
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.bottom="
                    lang('t_remaining') +
                    collection_statistics.remaining_collection
                  "
                  :value="collection_statistics.total_collection_payment"
                  :max="collection_statistics.collection_goal"
                  variant="primary"
                >
                </b-progress>
              </div>
              <div class="d-flex align-items-center">
                <b-dropdown
                  boundary="viewport"
                  menu-class="font-small-3 py-0"
                  size="sm"
                  :text="selected_time_period.display_name"
                  variant="flat-secondary"
                  class="bg-light-secondary mt-50 mr-auto w-75 mr-50"
                >
                  <b-dropdown-item
                    @click="
                      () => {
                        selected_time_period = item;
                      }
                    "
                    v-for="(item, i) in time_period"
                    :key="i"
                  >
                    {{ item.display_name }}
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  @click="modal_filter = true"
                  size="sm"
                  class="px-50 py-50 mt-50 ml-50"
                  variant="flat-secondary"
                >
                  <b-icon icon="funnel"></b-icon>
                </b-button>
              </div>
            </div>
            <div></div>
          </b-card-header>
          <b-card-body v-if="selected_time_period.internal_name == 'daily'">
            <vue-apex-charts
              width="99%"
              type="line"
              height="365"
              :options="collection_chart_options_daily.chartOptions"
              :series="collection_chart_options_daily.series"
            />
          </b-card-body>
          <b-card-body v-else>
            <vue-apex-charts
              width="99%"
              type="line"
              height="365"
              :options="collection_chart_options_monthly.chartOptions"
              :series="collection_chart_options_monthly.series"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="4" sm="12">
        <b-card no-body>
          <b-card-body>
            <b-media
              class="mb-1 d-flex align-items-center justify-content-center"
              no-body
            >
              <b-media-aside>
                <b-avatar rounded="lg" :variant="'light-primary'">
                  <i :class="['bi', 'bi-graph-up-arrow']"></i>
                </b-avatar>
              </b-media-aside>

              <b-media-body>
                <h6 class="font-weight-bolder mb-0 text-truncate">
                  {{ lang("t_target") }}
                </h6>
                <b-card-text
                  class="font-small-2 text-muted mb-0 text-truncate"
                  style="max-width: 10rem"
                >
                  <!-- {{ "Lorem ipsum" }} -->
                </b-card-text>
              </b-media-body>
              <b-media-aside right>
                <h5>
                  {{ collection_statistics.collection_goal }}
                </h5>
              </b-media-aside>
            </b-media>
            <b-media
              class="mb-1 d-flex align-items-center justify-content-center"
              no-body
            >
              <b-media-aside>
                <b-avatar rounded="lg" :variant="'light-success'">
                  <i :class="['bi', 'bi-check2-circle', 'font-medium-1']"></i>
                </b-avatar>
              </b-media-aside>

              <b-media-body>
                <h6 class="font-weight-bolder mb-0 text-truncate">
                  {{ lang("t_completed") }}
                </h6>
                <b-card-text
                  class="font-small-2 text-muted mb-0 text-truncate"
                  style="max-width: 10rem"
                >
                  <!-- {{ "Lorem ipsum" }} -->
                </b-card-text>
              </b-media-body>
              <b-media-aside right>
                <h5>
                  {{ collection_statistics.total_collection_payment }}
                </h5>
              </b-media-aside>
            </b-media>
            <b-media
              class="d-flex align-items-center justify-content-center"
              no-body
            >
              <b-media-aside>
                <b-avatar rounded="lg" :variant="'light-warning'">
                  <i :class="['bi', 'bi-circle']"></i>
                </b-avatar>
              </b-media-aside>

              <b-media-body>
                <h6 class="font-weight-bolder mb-0 text-truncate">
                  {{ lang("t_remaining") }}
                </h6>
                <b-card-text
                  class="font-small-2 text-muted mb-0 text-truncate"
                  style="max-width: 10rem"
                >
                  <!-- {{ "Lorem ipsum" }} -->
                </b-card-text>
              </b-media-body>
              <b-media-aside right>
                <h5>
                  {{ collection_statistics.remaining_collection }}
                </h5>
              </b-media-aside>
            </b-media>
          </b-card-body>
        </b-card>

        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title> {{ lang("t_events") }} </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              Son aktivite detayları
            </b-card-sub-title> -->
          </b-card-header>
          <b-card-body class="mb-2" style="height: 15.4rem; overflow: auto">
            <app-timeline>
              <app-timeline-item
                v-for="(event, i) in events"
                :key="i"
                :title="event.title"
                :subtitle="event.subtitle"
                :icon="event.icon"
                :variant="event.variant"
                :time="event.time"
              />

              <!-- <app-timeline-item
                title="Your are running low on time"
                subtitle="Only 30 minutes left to finish milestone"
                icon="ClockIcon"
                time="21 minutes ago"
                variant="warning"
              />

              <app-timeline-item
                title="Client Meeting"
                subtitle="New event has been added to your schedule"
                icon="UserIcon"
                time="36 minutes ago"
              />

              <app-timeline-item
                title="Product Design"
                subtitle="Product design added in workflow"
                icon="GridIcon"
                time="1 hour ago"
                variant="success"
              /> -->
            </app-timeline>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_filters')"
      centered
      v-model="modal_filter"
      @ok="GetCollectionStatistics(dashboard_info.selected_month)"
    >
      <b-form-group :label="lang('t_targetedMonth')" label-for="targeted_month">
        <flat-pickr
          v-model="dashboard_info.selected_month"
          class="form-control"
          :config="{ dateFormat: 'Y-m' }"
        />
      </b-form-group>
      <!-- <b-form-group :label="lang('t_agent')" label-for="extens">
        <v-select
          id="extens"
          v-model="dashboard_info.selected_agents"
          :options="agents"
          :placeholder="lang('t_agent')"
          :reduce="(val) => val.identifier"
          label="username"
          multiple
        ></v-select>
      </b-form-group> -->
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BPagination, BContainer,
  BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton, BCardHeader, BCardBody, BCardFooter, BAvatar, BDropdown, BDropdownItem,
  BCardTitle, BCardSubTitle, BProgress, BBadge, BMedia, BMediaAside, BMediaBody, VBTooltip, BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormSelect,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
export default {
  components: {
    AppTimeline, VBTooltip, BInputGroup, BFormSelect, BTable, BPagination, BContainer,
    AppTimelineItem, BCardHeader, BCardBody, BCardFooter, VueApexCharts, BMedia, BMediaAside, BMediaBody, BAvatar, BDropdown, BDropdownItem,
    BCardTitle, BCardSubTitle, BProgress, BInputGroupPrepend, BInputGroupAppend, BFormInput,
    BFormRadioGroup, BBadge, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton, vSelect, flatPickr
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.agent_list.slice(start, end);
    },

  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      collection_statistics: {},
      searchQuery: '',
      perPage: 10,
      currentPage: 1,
      modal_filter: false,
      events: [],
      agents: [],
      agent_list: [],
      queues: [],
      dashboard_info: {
        selected_month: '',
        selected_agents: [globalThis.user.identifier]
      },
      daily_chart_info: {
        "09": 0,
        "10": 0,
        "11": 0,
        "12": 0,
        "13": 0,
        "14": 0,
        "15": 0,
        "16": 0,
        "17": 0,
        "18": 0,
      },
      monthly_chart_info: {

      },
      collection_chart_options_daily: {
        series: [
          {
            name: globalThis._lang("t_completed"),
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: globalThis._lang("t_goal"),
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],

        chartOptions: {
          // title: {
          //   text: globalThis._lang("t_dailyTarget"),
          //   align: 'center',
          //   margin: 50,
          //   offsetX: 0,
          //   offsetY: 0,
          //   floating: false,
          //   style: {
          //     fontSize: '18px',
          //     fontWeight: 'bold',
          //     fontFamily: undefined,
          //     color: $themeColors.primary,
          //   },
          // },
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: ['smooth', 'straight'],
            dashArray: [0, 0],
            width: [3, 1],
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
            fontSize: "12px",
            fontFamily: "Montserrat",
            height: 40,
            // offsetY: -10
          },
          colors: [$themeColors.primary, $themeColors.secondary],
          // fill: {
          //   type: 'gradient',
          //   gradient: {
          //     shade: 'dark',
          //     inverseColors: false,
          //     gradientToColors: [$themeColors.primary, '#ebe9f1'],
          //     shadeIntensity: 1,
          //     type: 'horizontal',
          //     opacityFrom: 1,
          //     opacityTo: 1,
          //     stops: [0, 100, 100, 100],
          //   },
          // },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      collection_chart_options_monthly: {
        series: [
          {
            name: globalThis._lang("t_completed"),
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: globalThis._lang("t_goal"),
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],

        chartOptions: {
          // title: {
          //   text: globalThis._lang("t_monthlyTarget"),
          //   align: 'center',
          //   margin: 50,
          //   offsetX: 0,
          //   offsetY: 0,
          //   floating: false,
          //   style: {
          //     fontSize: '18px',
          //     fontWeight: 'bold',
          //     fontFamily: undefined,
          //     color: $themeColors.primary,
          //   },
          // },
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: ['smooth', 'straight'],
            dashArray: [0, 0],
            width: [3, 1],
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
            fontSize: "12px",
            fontFamily: "Montserrat",
            height: 40,
            // offsetY: -10
          },
          colors: [$themeColors.primary, $themeColors.secondary],
          // fill: {
          //   type: 'gradient',
          //   gradient: {
          //     shade: 'dark',
          //     inverseColors: false,
          //     gradientToColors: [$themeColors.primary, '#ebe9f1'],
          //     shadeIntensity: 1,
          //     type: 'horizontal',
          //     opacityFrom: 1,
          //     opacityTo: 1,
          //     stops: [0, 100, 100, 100],
          //   },
          // },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#f3f3f3',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      time_period: [
        { internal_name: 'daily', display_name: globalThis._lang('t_daily') },
        { internal_name: 'monthly', display_name: globalThis._lang('t_monthly') }
      ],
      selected_time_period: { internal_name: 'monthly', display_name: globalThis._lang('t_monthly') },
      tableColumns: [
        {
          key: 'agent',
          label: globalThis._lang('t_agent'),
          sortable: true
        },
        {
          key: 'goal',
          label: globalThis._lang('t_goal'),
          sortable: true
        },
        {
          key: 'completed',
          label: globalThis._lang('t_completed'),
          sortable: true
        },
        {
          key: 'remaining',
          label: globalThis._lang('t_remaining'),
          sortable: true
        },
        {
          key: 'remaining_rate',
          label: globalThis._lang('t_rate'),
          sortable: true
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '5vh' }
        },
        // {
        //   key: 'actions',
        //   label: '',
        //   sortable: true,
        //   thStyle: { width: '5vh' }
        // }
      ]
    }
  },

  methods: {
    GetCollectionEvents: async function () {

      var response = (
        await this.$http_in.get(`agent/v1/CollectionEvents`)
      ).data;

      for (const item of response) {
        let obj = {};
        if (item.type == 'goal') {
          obj.title = `${this.lang('t_targeting')} - (${this.queues.find(e => e.internal_name == item.queue) ? this.queues.find(e => e.internal_name == item.queue).display_name : item.queue})`;
          obj.subtitle = `${this.lang('t_date')}: ${item.collection_month} => ${this.lang('t_target')}:${item.collection_goal}`;
          obj.icon = `AwardIcon`;
          obj.variant = `info`;
          obj._id = item._id;

        } else {
          obj.title = `${this.lang('t_targetCompletion')} (${this.queues.find(e => e.internal_name == item.queue) ? this.queues.find(e => e.internal_name == item.queue).display_name : item.queue})`;
          obj.subtitle = `${this.lang('t_date')}: ${item.collection_date} ${item.collection_time} => ${this.lang('t_completed')}: ${item.collection_payment}`;
          obj.icon = `CheckIcon`;
          obj.variant = `success`;
          obj._id = item._id;

        }
        this.events.push(obj);

      }
      this.events = this.events.sort((a, b) => {
        if (a._id < b._id) {
          return -1;
        }
        if (a._id > b._id) {
          return 1;
        }
        return 0;
      });


    },
    GetCollectionStatistics: async function (_date) {

      var response = (
        await this.$http_in.get(`spv/v1/CollectionStatisticsDashboard/${_date}/${JSON.stringify(this.dashboard_info.selected_agents)}`)
      ).data;

      this.agent_list = response.agent_list;
      console.log("res", response);
      this.collection_statistics = {
        ...response.bar_info,
      };
      this.collection_statistics['selected_month'] = new Date(_date).toLocaleString('default', { month: 'long' });

      this.daily_chart_info = {
        "09": 0,
        "10": 0,
        "11": 0,
        "12": 0,
        "13": 0,
        "14": 0,
        "15": 0,
        "16": 0,
        "17": 0,
        "18": 0,
      };

      this.monthly_chart_info = {};
      for (const item of Object.keys(this.daily_chart_info)) {
        for (const item2 of response.daily_chart_info) {
          if (parseInt(item2.collection_time) <= parseInt(item)) {
            this.daily_chart_info[item] +=
              parseInt(item2.collection_payment) || 0;
          }
        }
      }

      var hourly_goals = [];
      for (const index in Object.keys(this.daily_chart_info)) {
        hourly_goals.push(
          ((parseInt(index) + 1) *
            parseInt(this.collection_statistics.daily_collection_goal)) /
          10
        );
      }
      var _tmp = Object.values(this.daily_chart_info);
      _tmp.sort(function (a, b) {
        return a - b;
      });
      console.log("tmp", _tmp);
      console.log("hourly_goals", hourly_goals);
      // this.collection_chart_options_daily.series[0].data = _tmp;
      // this.collection_chart_options_daily.series[1].data = hourly_goals;

      this.collection_chart_options_daily.chartOptions = { ...this.collection_chart_options_daily.chartOptions, categories: Object.keys(this.daily_chart_info) };
      this.collection_chart_options_daily.series[0].data = _tmp;
      this.collection_chart_options_daily.series[1].data = hourly_goals;
      this.collection_chart_options_daily.series[1].data = hourly_goals;


      let date = new Date(_date);
      this.collection_statistics['selected_month'] = new Date(_date).toLocaleString('default', { month: 'long' });
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      var _bussiness_days = this.businessDays(firstDay, lastDay);
      var bussiness_days = _bussiness_days.length;
      var daily_goals = [];
      var daily_goals_columns = [];
      console.log("_bussiness_days", _bussiness_days);
      for (let index = 0; index < bussiness_days; index++) {
        // daily_goals_columns.push(parseInt(index) + 1);
        daily_goals.push(
          parseInt(
            ((parseInt(index) + 1) *
              parseInt(this.collection_statistics.collection_goal)) /
            bussiness_days
          )
        );
      }

      var _array = [];
      for (const item of _bussiness_days) {
        this.monthly_chart_info[item] = 0;
        console.log("item", item);
        for (const item3 of response.monthly_chart_info) {
          console.log("item3", item3);

          if (new Date(item3.collection_date).getDate() <= parseInt(item)) {
            console.log("item33", parseInt(item3.collection_payment));

            this.monthly_chart_info[item] += parseInt(item3.collection_payment);
          }

        }
        this.modal_filter = false;
      }
      console.log("this.monthly_chart_info", this.monthly_chart_info);
      var _tmp = Object.values(this.monthly_chart_info);
      _tmp.sort(function (a, b) {
        return a - b;
      });
      console.log("daily_goals", daily_goals);

      this.collection_chart_options_monthly.chartOptions = { ...this.collection_chart_options_monthly.chartOptions, categories: _bussiness_days };
      this.collection_chart_options_monthly.series[0].data = _tmp;
      this.collection_chart_options_monthly.series[1].data = daily_goals;
      this.collection_statistics['selected_month'] = new Date(_date).toLocaleString('default', { month: 'long' });



    },
    businessDays(date1, date2) {
      var days = ['N', 'Y', 'Y', 'Y', 'Y', 'Y', 'N'];

      var d1 = new Date(date1);
      var d2 = new Date(date2);

      var isGunu = 0;
      var _days = [];
      while (true) {

        if (d1 > d2) {
          break;
        }

        var dayName = days[d1.getDay()];

        if (dayName != "N") {
          isGunu++;
          _days.push(d1.getDate());
        }

        d1.setDate(d1.getDate() + 1);
      }
      return _days;
    },
    get_agents: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/User`)
      ).data;

      this.agents = response;
    },
  },
  mounted: function () {
    this.get_agents();
    this.queues = globalThis.queues;
    this.GetCollectionEvents();

    let date = new Date();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    this.dashboard_info.selected_month = `${date.getFullYear()}-${month}`;
    this.GetCollectionStatistics(this.dashboard_info.selected_month);

  },

}
</script>

<style>
</style>
