<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card style="height: 56rem" class="mb-0">
          <b-card-text class="text-center">
            <b-avatar
              v-if="user"
              size="4rem"
              variant="light-secondary"
              :text="user.name[0] + user.surname[0]"
            >
            </b-avatar>
          </b-card-text>
          <b-card-text v-if="user" class="text-center">
            <span class="d-block font-medium-3 font-weight-bolder">{{
              user.name + " " + user.surname
            }}</span>
            <span class="text-muted">{{
              user.role ? user.role : "admin"
            }}</span>
          </b-card-text>
          <b-card-text v-if="user" class="text-center border-top">
            <div class="text-truncate d-flex align-items-center pb-1">
              <span class="text-muted text-center pt-1 text-uppercase">
                {{ lang("t_userInformations") }}
              </span>
            </div>
            <div
              class="mb-50 text-truncate text-left d-flex align-items-center"
            >
              <span class="font-weight-bolder">{{
                lang("t_extension") + ":"
              }}</span>
              <span class="ml-50"> {{ user.exten }} </span>
            </div>
            <div
              class="mb-50 text-truncate text-left d-flex align-items-center"
            >
              <span class="font-weight-bolder">{{
                lang("t_eMail") + ":"
              }}</span>
              <span class="ml-50"> {{ user.email }} </span>
            </div>
            <div
              class="mb-50 text-truncate text-left d-flex align-items-center"
            >
              <span class="font-weight-bolder">{{
                lang("t_project") + ":"
              }}</span>
              <span class="ml-50">
                {{ getProjectsDisplayNames(user.projects) }}
              </span>
            </div>
            <div
              class="mb-50 text-truncate text-left d-flex align-items-center"
            >
              <span class="font-weight-bolder">{{
                lang("t_queue") + ":"
              }}</span>
              <b-badge
                v-for="item in user.selected_queues"
                :key="item"
                class="ml-50"
                variant="light-primary"
              >
                {{
                  queues.find((e) => e.internal_name == item)
                    ? queues.find((e) => e.internal_name == item).display_name
                    : item
                }}
              </b-badge>
            </div>
            <div
              class="mb-50 text-truncate text-left d-flex align-items-center"
            >
              <span class="font-weight-bolder">{{
                lang("t_lastLogin") + ":"
              }}</span>
              <span class="ml-50">
                {{ convertDateFormat(user.loggedInAt) }}
              </span>
            </div>
            <div
              v-if="user.shift"
              class="mb-50 text-truncate text-left d-flex align-items-center"
            >
              <span class="font-weight-bolder">{{
                lang("t_shift") + ":"
              }}</span>
              <span class="ml-50">
                {{ `${user.shift.shift_start} - ${user.shift.shift_end}` }}
              </span>
            </div>
            <div
              v-if="user.shift"
              class="mb-50 text-truncate text-left d-flex align-items-center"
            >
              <span class="font-weight-bolder">{{
                lang("t_breakTime") + ":"
              }}</span>
              <span class="ml-50">
                {{ user.shift.break_time + " " + lang("t_minute") }}
              </span>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-tabs
          lazy
          pills
          active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
        >
          <b-tab ref="test" active>
            <template #title>
              <i class="bi bi-clipboard-data font-medium-1"></i>
              <span>{{ lang("t_quality") }}</span>
            </template>
            <performance></performance>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="bi bi-bullseye font-medium-1"></i>
              <span>{{ lang("t_target") }}</span>
            </template>
            <goal></goal>
          </b-tab>
          <b-tab disabled :title="lang('t_notifications')">
            <template #title>
              <i class="bi bi-bell font-medium-1"></i>
              <span>{{ lang("t_notifications") }}</span>
            </template>
          </b-tab>
          <b-tab :title="lang('t_security')">
            <template #title>
              <i class="bi bi-lock font-medium-1"></i>
              <span>{{ lang("t_security") }}</span>
            </template>
            <security></security>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol,
  BCard, BFormGroup, BFormInput, BTable, BBadge, BModal, BButtonGroup, BDropdown, BImg, BFormCheckbox, VBTooltip, BButton, BLink,
  BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BRow, BCardBody, BCardText, BTabs, BTab,
} from 'bootstrap-vue'

import { $themeColors } from "@themeConfig";

import Performance from './EvaluatedRecords.vue';
import Goal from './Goal.vue';
import Security from './Security.vue';
export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      queues: [],
      projects: [],
      user: null,

    }
  },
  components: {
    Security,
    Goal,
    Performance,
    BCardBody, BCardText, BTabs, BTab,
    BCol,
    BFormGroup,
    BTable, BBadge, BFormInput,
    BModal,
    BButtonGroup,
    BRow,
    BButton,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg, BFormCheckbox,
    BCard,

  },
  methods: {
    convertDateFormat(timestamp) {
      const dateTime = new Date(timestamp);

      if (isNaN(dateTime.getTime())) {
        return 'Invalid date';
      }

      const day = ('0' + dateTime.getDate()).slice(-2);
      const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
      const year = dateTime.getFullYear();
      const hours = ('0' + dateTime.getHours()).slice(-2);
      const minutes = ('0' + dateTime.getMinutes()).slice(-2);
      const seconds = ('0' + dateTime.getSeconds()).slice(-2);

      const newFormat = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      return newFormat;
    },
    getProjectsDisplayNames(pf) {
      let _projects = pf;
      let text = "";
      for (const item of _projects) {
        text +=
          this.projects.find((e) => e.internal_name == item) != undefined
            ? this.projects.find((e) => e.internal_name == item)
              .display_name + ","
            : "";

      }
      text = text.substr(0, text.length - 1);
      return text;
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mounted: function () {
    this.queues = globalThis.queues;
    this.projects = globalThis.projects;
    this.user = globalThis.user;
  }
}

</script>
